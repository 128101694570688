body {
  margin: 0;
  padding: 0;
  font-family: 'TTChocolate-Regular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background-color: #ffecd6;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

.App-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  height: 120px;
}

.App-header-link {
  color: #443f39;
  margin: 5vmin 10vmin;
  text-decoration: none;
}

.App-header-link:hover {
  text-decoration: underline;
}

.App-link {
  color: #443f39;
  margin: 10px;
  text-decoration: none;
}

.App-link:hover {
  text-decoration: none;
}

.App-link-container {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 14px;
  margin-bottom: 20px;
  width: 200px;
}

.App-links {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.App-link-logo {
  margin: 10px;
  max-height: 75px;
}

.App-link-null:hover {
  cursor: not-allowed;
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #443f39;
}

.App-work-main {
  margin-top: 30px;
}

@media screen and (min-width: 420px) {
  .App-links {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .App-link-container {
    font-size: 18px;
  }
}

@font-face {
    font-family: 'TTChocolates-Bold';
    src: url(/static/media/TTChocolates-BoldItalic.c2192cf7.woff2) format('woff2'),
        url(/static/media/TTChocolates-BoldItalic.b267554f.woff) format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TTChocolates-Light';
    src: url(/static/media/TTChocolates-Light.ebd1813f.woff2) format('woff2'),
        url(/static/media/TTChocolates-Light.803f922c.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TTChocolate-Regular';
    src: url(/static/media/TTChocolates-Regular.724c044b.woff2) format('woff2'),
        url(/static/media/TTChocolates-Regular.78fd534b.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TTChocolates-Italic';
    src: url(/static/media/TTChocolates-Italic.d58427ca.woff2) format('woff2'),
        url(/static/media/TTChocolates-Italic.88ea61bb.woff) format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'TTChocolates-Black';
    src: url(/static/media/TTChocolates-Black.6b358842.woff2) format('woff2'),
        url(/static/media/TTChocolates-Black.1a95c16b.woff) format('woff');
    font-weight: 900;
    font-style: normal;
}


