.App {
  background-color: #ffecd6;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: row-reverse;
  height: 120px;
}

.App-header-link {
  color: #443f39;
  margin: 5vmin 10vmin;
  text-decoration: none;
}

.App-header-link:hover {
  text-decoration: underline;
}

.App-link {
  color: #443f39;
  margin: 10px;
  text-decoration: none;
}

.App-link:hover {
  text-decoration: none;
}

.App-link-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 20px;
  width: 200px;
}

.App-links {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-link-logo {
  margin: 10px;
  max-height: 75px;
}

.App-link-null:hover {
  cursor: not-allowed;
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #443f39;
}

.App-work-main {
  margin-top: 30px;
}

@media screen and (min-width: 420px) {
  .App-links {
    flex-direction: row;
  }

  .App-link-container {
    font-size: 18px;
  }
}
