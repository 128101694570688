@font-face {
    font-family: 'TTChocolates-Bold';
    src: url('TTChocolates-BoldItalic.woff2') format('woff2'),
        url('TTChocolates-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TTChocolates-Light';
    src: url('TTChocolates-Light.woff2') format('woff2'),
        url('TTChocolates-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TTChocolate-Regular';
    src: url('TTChocolates-Regular.woff2') format('woff2'),
        url('TTChocolates-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TTChocolates-Italic';
    src: url('TTChocolates-Italic.woff2') format('woff2'),
        url('TTChocolates-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'TTChocolates-Black';
    src: url('TTChocolates-Black.woff2') format('woff2'),
        url('TTChocolates-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

